import React, { useState, useEffect } from "react";
import Tabs from "../components/tabs/tabs";
import { useDocTitle } from "../utils/customHooks";
import axios from "axios";

export const FunctionalBookmark = () => {
  const [doctitle, setDocTitle] = useDocTitle("Bookmark");
  const [userBookmarks, setUserBookmarks] = useState();

  useEffect(() => {
    let token = getToken();

    if (token) {
      try {
        token = JSON.parse(token);
        getBookmarkedData(token.access_token)
          .then((response) => {
            // console.log(response);
            setUserBookmarks(response.data);
          })
          .catch((err) => console.log(err));
      } catch (e) {
        // console.log(e);
      }
    } else {
      try {
        let favourites = JSON.parse(localStorage.getItem("favourites"));
        let bookmarks = {
          data: {
            results: favourites,
          },
        };
        console.log(bookmarks);
        setUserBookmarks(bookmarks);
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const getBookmarkedData = (access_token) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    return axios.get(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_GET_BOOKMARKS_URL,
      { headers: headers }
    );
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const toggleBookmark = async (access_token, data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    return await axios.post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_BOOKMARK_TOGGLE_URL,
      data,
      { headers: headers }
    );
  };

  const handleBookmarkToggle = (e, value) => {
    if (e.target.classList.contains("fa-heart")) {
      e.target.classList.remove("fa-heart");
      e.target.classList.add("fa-heart-o");
    } else {
      e.target.classList.remove("fa-heart-o");
      e.target.classList.add("fa-heart");
    }

    const bookmarkData = userBookmarks.data.results.filter(
      (menu) => menu.place_id === value
    );

    let token = getToken();
    let data = bookmarkData[0];

    // console.log(bookmarkData[0]);

    if (value && bookmarkData[0].place_id) {
      if (token) {
        try {
          token = JSON.parse(token);
          toggleBookmark(token.access_token, data)
            .then((response) => {
              console.log(response.data.msg);
              // alert(response.data.msg);
            })
            .catch((err) => {
              console.log(err.response.data.msg);
              // alert(err.response.data.msg);
            });
        } catch (e) {
          // console.log(e);
        }
      } else {
        let favourites = localStorage.getItem("favourites");
        if (favourites) {
          try {
            favourites = JSON.parse(favourites);
            // favourites.push(bookmarkData[0]);
            favourites = favourites.filter(function( obj ) {
              return obj.place_id !== bookmarkData[0].place_id;
            });
            const serializedState = JSON.stringify(favourites);
            localStorage.setItem("favourites", serializedState);
          } catch (err) {
            return undefined;
          }
        } else {
          try {
            const serializedState = JSON.stringify(bookmarkData);
            localStorage.setItem("favourites", serializedState);
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  };
  const handleShare = (geometry, name) => {
    const url = process.env.REACT_APP_SHARE_LINK + geometry;
    navigator.clipboard.writeText(url);
    alert(name + "\n" + "location copied successfully");
  };

  const handleDirections = (geometry) => {
    const url = process.env.REACT_APP_SHARE_LINK + geometry;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const getPlaceDetails = async (access_token, data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    return await axios.post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_PLACE_DETAILS_URL,
      data,
      { headers: headers }
    );
  };

  const handlePlaceDetails = (value) => {
    let token = getToken();
    let data = {
      place_id: value,
    };

    if (value) {
      try {
        token = JSON.parse(token);
        getPlaceDetails(token.access_token, data)
          .then((response) => {
            // console.log(response.data);
            if (
              response.data.data &&
              response.data.data.result &&
              response.data.data.result.international_phone_number
            ) {
              alert(response.data.data.result.international_phone_number);
            } else {
              alert("Phone not available");
            }
          })
          .catch((err) => {
            // console.log(err)
            alert(err.response);
          });
      } catch (e) {
        // console.log(e);
      }
    }
  };

  return (
    <>
      <Tabs />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <nav
                className="navbar navbar-expand-lg navbar-light bg-light"
                style={{ borderRadius: "50px", margin: "10px 0px 20px 0px" }}
              >
                <div className="container-fluid">
                  <a className="navbar-brand" href="#">
                    Favourite Places
                  </a>
                  {/* <div className="">
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Search in response"
                      // value={responseSearch}
                      onChange={(e) =>
                        // handleResponseSearch(e.target.value)
                        alert("Search")
                      }
                    />
                  </div> */}
                </div>
              </nav>
            </div>

            <div className="row" style={{ marginBottom: "100px" }}>
              {/* ? */}

              {userBookmarks &&
                userBookmarks.data &&
                userBookmarks.data.results &&
                userBookmarks.data.results.map(function (menu, index) {
                  return (
                    <div
                      className="col-sm-4 card-block"
                      style={{ marginBottom: "20px", minWidth: "200px" }}
                      key={menu.place_id}
                    >
                      <div
                        className="card  card-fancy"
                        style={{ borderRadius: "15px" }}
                      >
                        <div className="card-body">
                          <div className="card-title">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex flex-row align-items-center">
                                <div
                                  className="ms-2 text-center"
                                  title={menu.name}
                                >
                                  <h6
                                    className="mb-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {menu.name.length > 40
                                      ? menu.name.substr(0, 40) + "..."
                                      : menu.name}
                                  </h6>
                                </div>
                              </div>
                              <div className="badge-custom">
                                <span title="save">
                                  <button
                                    type="button"
                                    style={{ color: "red" }}
                                    id="save"
                                    className={
                                      menu.bookmark
                                        ? "fa fa-heart"
                                        : "fa fa-heart-o"
                                    }
                                    onClick={(e) =>
                                      handleBookmarkToggle(e, menu.place_id)
                                    }
                                  ></button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row  mt-2 mb-2">
                            <div
                              className="col text-center"
                              style={{ borderRight: "1px solid grey" }}
                            >
                              <small className="span-content">
                                {menu.km} KM
                              </small>
                            </div>
                            <div
                              className="col text-center"
                              style={{ borderRight: "1px solid grey" }}
                            >
                              <span
                                className="badge badge-pill ms-2 badge-custom-2"
                                style={{
                                  backgroundColor: menu.open_now
                                    ? "green"
                                    : "red",
                                }}
                              >
                                {menu.open_now ? "OPEN" : "CLOSED"}
                              </span>
                            </div>
                            <div className="col text-center">
                              <span title="copy">
                                <button
                                  type="button"
                                  id="save"
                                  className="fa fa-copy"
                                  onClick={(e) =>
                                    handleShare(
                                      menu.geometry.lat +
                                        "," +
                                        menu.geometry.lng,
                                      menu.name
                                    )
                                  }
                                ></button>
                              </span>
                            </div>
                          </div>
                          <p
                            className="card-text text-center mt-4"
                            style={{ fontSize: "12px" }}
                            title={menu.vicinity}
                          >
                            {menu.vicinity.length > 60
                              ? menu.vicinity.substr(0, 60) + "..."
                              : menu.vicinity}
                          </p>
                          <div className="call-directions">
                            <span
                              className="btn btn-outline-success pull-left"
                              style={{ borderRadius: "50px", fontSize: "10px" }}
                              onClick={(e) => handlePlaceDetails(menu.place_id)}
                            >
                              <i
                                className="fa fa-phone"
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Call
                            </span>

                            <span
                              className="btn btn-outline-primary pull-right"
                              style={{ borderRadius: "50px", fontSize: "10px" }}
                              onClick={(e) =>
                                handleDirections(
                                  menu.geometry.lat + "," + menu.geometry.lng
                                )
                              }
                            >
                              <i
                                className="fa fa-location-arrow"
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Directions
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* ? */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
