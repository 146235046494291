import React, { Component } from "react";
import axios from "axios";
import { Redirect } from 'react-router';


async function resetPassword(data) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return axios
    .post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_RESET_PASSWORD_URL,
      data,
      { headers }
    )
    .then((response) => response.data)
    .catch((err) => err.response.data);
}

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password_confirmation: "",
      token: "",
      resetPasswordError: "",
      isLoading: false,
      redirect: false,
      redirectTo: "",
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillMount() {
    document.title = "Reset Password";
  }

  handleTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  

  async handleFormSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const token = await resetPassword({
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
      token: this.state.token,
    });

    this.setState({ isLoading: false });

    console.log(token);

    if (token.statusCode === 200) {
      alert(token.msg);
      this.setState({ redirect: true, redirectTo: "/login" });
    } else {
      alert('Failure');
    }
  }

  render() {
    const { redirect, redirectTo } = this.state;

    if (redirect) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <div
        className="container"
        style={{
          position: "relative",
          top: "10%",
          bottom: "10%",
          alignItems: "center",
        }}
      >
        <div className="row">
          <div className="col-md-4 offset-md-4 col-sm-12">
            <div className="auth-inner-card">
              <form onSubmit={this.handleFormSubmit}>
                <h3 className="text-center" style={{ marginBottom: "30px" }}>
                  Reset Password
                </h3>

                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleTextChange}
                  />
                </div>

                <div className="col-md-12">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleTextChange}
                  />
                </div>

                <div className="col-md-12">
                  <label htmlFor="password_confirmation" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="password_confirmation"
                    className="form-control"
                    placeholder="Password Confirmation"
                    name="password_confirmation"
                    value={this.state.password_confirmation}
                    onChange={this.handleTextChange}
                  />
                </div>

                <div className="col-md-12">
                  <label htmlFor="token" className="form-label">
                    Token
                  </label>
                  <input
                    type="text"
                    id="token"
                    className="form-control"
                    placeholder="Secret Token"
                    name="token"
                    value={this.state.token}
                    onChange={this.handleTextChange}
                  />
                </div>

                <div className="form-group margin-10">
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.resetPasswordError !== ""
                      ? this.state.resetPasswordError
                      : ""}
                  </span>
                </div>

                <div className="d-grid gap-2 margin-10">
                  <button
                    type="submit"
                    className="btn btn-primary btn-md btn-block"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading && (
                      <i
                        className="fa fa-spinner fa-pulse"
                        aria-hidden="true"
                        style={{ marginRight: "20px" }}
                      ></i>
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
