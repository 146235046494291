import React from "react";
import { Link } from "react-router-dom";

export const FunctionalNotFound = () => {
  return (
    <div
      className="container"
      style={{
        position: "relative",
        top: "10%",
        bottom: "10%",
        alignItems: "center",
      }}
    >
      <div className="row">
        <div className="col-md-4 offset-md-4 col-sm-12">
          <div className="auth-inner-card">
            <h3 className="text-center">Route Not Found</h3>
            <h6  className="text-center">
              <Link to={"/login"}>Login</Link>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
