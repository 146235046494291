import React, { useState, useEffect, useRef } from "react";
import Tabs from "../components/tabs/tabs";
import { FaMapMarkedAlt } from "react-icons/fa";
import { useDocTitle } from "../utils/customHooks";
import { exploreList } from "../assets/json/exploreList";
import axios from "axios";
import spinnerLogo2 from "../assets/gif/location-4.gif";

export const FunctionalExplore = () => {
  const [doctitle, setDocTitle] = useDocTitle("Explore");
  const [searchText, setSearchText] = useState("");
  const [exploreListArray, setExploreListArray] = useState(exploreList);
  const [nearBySearchData, setNearBySearchData] = useState([]);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [nearBySearchDataArray, setNearBySearchDataArray] = useState([]);
  const [responseSearch, setResponseSearch] = useState("");
  const [keyword, setKeyword] = useState("");
  const [sidebar, setSidebar] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isMoreDataLoading, setIsMoreDataLoading] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [locationAccess, setLocationAccess] = useState(false);

  useEffect(() => {
    if (navigator.geolocation) {
      const pos = navigator.geolocation.getCurrentPosition(function (position) {
        // console.log(position);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });

      setLocationAccess(true);
    } else {
      setLocationAccess(false);
    }
  }, []);

  useEffect(() => {
    if (searchText) {
      // console.log( exploreList.filter((list) => list.title.toLowerCase().includes(searchText.toLowerCase())) );

      let filterExploreData = exploreList.filter((list) =>
        list.title.toLowerCase().includes(searchText.toLowerCase())
      );
      const defaultExploreData = exploreList.find(
        (list) => list.default === true
      );
      defaultExploreData.title = searchText;
      if (filterExploreData.length) {
        setExploreListArray(filterExploreData);
      } else {
        setExploreListArray([defaultExploreData]);
      }
    } else {
      setExploreListArray(exploreList);
    }
  }, [searchText, `${exploreList}`]);

  const handlePillClick = async (value) => {
    setKeyword(value);
    setIsDataLoading(true);
    // setSidebar(true);
    let sidebar = document.querySelector("#toggleSidebarButton");
    sidebar.click();

    // let token = getToken();

    let data = {
      location: lat + "," + lng,
      keyword: value,
    };

    setNearBySearchDataArray([]);
    setNearBySearchData([]);

    try {
      // token = JSON.parse(token);

      await getByKeyword(data)
        .then((response) => {
          console.log(response.data);
          setNearBySearchDataArray(response.data);
          setNearBySearchData(response.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.msg) {
            alert(err.response.data.msg);
          }
        });
    } catch (e) {
      // console.log(e);
    }

    setIsDataLoading(false);
  };

  const getByKeyword = async (data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // console.log(process.env.REACT_APP_SERVER_BASE_URL + process.env.REACT_APP_NEARBYSEARCH_URL);
    // console.log(
    //   process.env.REACT_APP_SERVER_BASE_URL +
    //     process.env.REACT_APP_NEARBYSEARCH_URL_WITHOUT_AUTH
    // );
    // console.log(headers);
    return await axios.post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_NEARBYSEARCH_URL_WITHOUT_AUTH,
      data,
      { headers: headers }
    );
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const handleResponseSearch = (responseSearch) => {
    if (responseSearch) {
    }
  };

  const toggleBookmark = async (access_token, data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    return await axios.post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_BOOKMARK_TOGGLE_URL,
      data,
      { headers: headers }
    );
  };

  const handleBookmarkToggle = (e, value) => {
    if (e.target.classList.contains("fa-heart")) {
      e.target.classList.remove("fa-heart");
      e.target.classList.add("fa-heart-o");
    } else {
      e.target.classList.remove("fa-heart-o");
      e.target.classList.add("fa-heart");
    }

    const bookmarkData = nearBySearchDataArray.data.results.filter(
      (menu) => menu.place_id === value
    );
    // console.log(bookmarkData[0]);

    let token = getToken();
    let data = bookmarkData[0];

    if (value && bookmarkData[0].place_id) {
      if (token) {
        try {
          token = JSON.parse(token);
          toggleBookmark(token.access_token, data)
            .then((response) => {
              console.log(response.data.msg);
              // alert(response.data.msg);
            })
            .catch((err) => {
              console.log(err.response.data.msg);
              // alert(err.response.data.msg);
            });
        } catch (e) {
          // console.log(e);
        }
      } else {
        let favourites = localStorage.getItem("favourites");
        if (favourites) {
          try {
            favourites = JSON.parse(favourites);

            let fav1 = favourites.find(
              (o) => o.place_id === bookmarkData[0].place_id
            );

            if (!fav1) {
              bookmarkData[0].bookmark = true;
              favourites.push(bookmarkData[0]);
              const serializedState = JSON.stringify(favourites);
              localStorage.setItem("favourites", serializedState);
            }
          } catch (err) {
            return undefined;
          }
        } else {
          try {
            const serializedState = JSON.stringify(bookmarkData);
            localStorage.setItem("favourites", serializedState);
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  };

  const getPlaceDetails = async (data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    return await axios.post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_PLACE_DETAILS_URL_WITHOUT_AUTH,
      data,
      { headers: headers }
    );
  };

  const handlePlaceDetails = (value) => {
    // let token = getToken();
    let data = {
      place_id: value,
    };

    if (value) {
      try {
        // token = JSON.parse(token);
        getPlaceDetails(data)
          .then((response) => {
            // console.log(response.data);
            if (
              response.data.data &&
              response.data.data.result &&
              response.data.data.result.international_phone_number
            ) {
              var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
              // alert(response.data.data.result.international_phone_number);
              if (isMobile) {
                window.open('tel:'+response.data.data.result.international_phone_number);
              } else {
                navigator.clipboard.writeText(response.data.data.result.international_phone_number);
                alert(response.data.data.result.international_phone_number + " : Copied");
              }
              
            } else {
              alert("Phone not available");
            }
          })
          .catch((err) => {
            // console.log(err)
            alert(err.response);
          });
      } catch (e) {
        // console.log(e);
      }
    }
  };

  const handleShare = (geometry, name) => {
    const url = process.env.REACT_APP_SHARE_LINK + geometry;
    navigator.clipboard.writeText(url);
    alert(name + "\n" + "location copied successfully");
  };

  const handleDirections = (geometry) => {
    const url = process.env.REACT_APP_SHARE_LINK + geometry;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const loadMoreGooglePlaces = async (next_page_token) => {
    // alert(next_page_token)

    if (next_page_token) {
      // let token = getToken();
      // token = JSON.parse(token);
      let data = {
        next_page_token: next_page_token,
        location: lat + "," + lng,
        keyword: keyword,
      };

      setIsMoreDataLoading(true);

      await getByKeyword(data)
        .then((response) => {
          let newResult = { ...nearBySearchData };
          newResult.data.next_page_token = response.data.data.next_page_token;
          newResult.data.results.push(...response.data.data.results);

          // console.log('%O',newResult.data.next_page_token);
          // console.log('%N',response.data.data.next_page_token);
          // console.log(newResult);
          setNearBySearchData(newResult);
        })
        .catch((err) => {
          // console.log(err);
        });

      setIsMoreDataLoading(false);
    } else {
      // console.log("%NPT", next_page_token);
    }
  };

  const handleSidebarToggle = () => {
    setSidebar(!sidebar);
  };

  const handleScroll = () => {
    const el = document.getElementById("scrollDiv");
    el.scrollTo(0, 0);
  };

  return (
    <>
      <Tabs />

      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* <nav id="sidebar" className={sidebar ? "active" : ""}> */}
              <nav
                style={sidebar ? { display: "none" } : { display: "block" }}
              >
                <div className="sidebar-header">
                  <div
                    style={{
                      borderRadius: "50px",
                      marginTop: "10px",
                      marginBottom: "20px",
                    }}
                    className="navbar navbar-expand-lg navbar-light bg-light"
                  >
                    <div className="SearchBox col-sm-12">
                      <span
                        className="SearchBox-pin"
                        style={{ backgroundColor: "white" }}
                      >
                        <FaMapMarkedAlt style={{ fontSize: "30px" }} />
                      </span>

                      <input
                        type="text"
                        placeholder="Search here"
                        style={{ width: "100%", padding: "20px 0px 20px 10px" }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />

                      <button className="SearchBox-button pull-right">
                        <i
                          className="fa fa-search"
                          style={{ fontSize: "25px" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12 "
                    style={{ height: "75vh", overflowY: "scroll" }}
                    id="scrollDiv"
                  >
                    <div className="row">
                      {exploreListArray.map(function (list, index) {
                        return (
                          <div
                            className={
                              index % 3 === 0 ? "col-sm-12 col-md-4 mb-2" : "col mb-2"
                            }
                            key={index}
                          >
                            <div
                              className="card btn btn-pills"
                              style={{ borderRadius: "15px" }}
                              onClick={(e) => handlePillClick(list.title)}
                            >
                              <div className="card-body">
                                <div className="card-title">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="icon">
                                        <img
                                          src={list.img}
                                          alt=""
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                          }}
                                        />
                                      </div>
                                      <div className="ms-2">
                                        <h6
                                          className="mb-0 btn-pills-text"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {list.title.replace(
                                            /(^|\s)\S/g,
                                            (l) => l.toUpperCase()
                                          )}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {!searchText && (
                        <div className="col-sm-12 mb-2">
                          <div
                            className="card btn btn-pills"
                            style={{ borderRadius: "15px" }}
                            onClick={handleScroll}
                          >
                            <div className="card-body">
                              <div className="card-title">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-row align-items-center">
                                    <div className="icon">
                                      <i className="fa fa-arrow-up fa-3x"></i>
                                    </div>
                                    <div className="ms-2">
                                      <h6
                                        className="mb-0 btn-pills-text"
                                        style={{ fontSize: "14px" }}
                                      >
                                        Back To Top
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div
          className="container-fluid"
          style={!sidebar ? { display: "none" } : { display: "block" }}
        >
          <div className="row">
            <nav
              className="navbar navbar-expand-lg navbar-light bg-light"
              style={{ borderRadius: "50px", margin: "10px 0px 20px 0px" }}
            >
              <div className="container-fluid  text-center">
                <button
                  type="button"
                  id="toggleSidebarButton"
                  className="btn btn-info"
                  onClick={(e) => handleSidebarToggle(e)}
                >
                  <i className="fa fa-align-justify"></i>
                </button>

                <span className="navbar-brand  text-center">
                  Places based on Query
                </span>
              </div>
            </nav>
          </div>

          <div className="row">
            <div
              className="col-md-12"
              style={{
                height: "75vh",
                overflowY: "scroll",
              }}
            >
              {isDataLoading && (
                <div className="centered">
                  <div className="img img-responsive" role="status">
                    {/* <span className="visually-hidden">Loading...</span> */}
                    <img
                      src={spinnerLogo2}
                      alt="loader"
                      style={{ height: "100px" }}
                    />
                  </div>
                </div>
              )}

              <div className="row">
                {/* ? */}

                {nearBySearchDataArray.data &&
                  nearBySearchDataArray.data.results &&
                  nearBySearchDataArray.data.results.map(function (
                    menu,
                    index
                  ) {
                    // console.log(menu);
                    return (
                      <div
                        className="col-sm-4 card-block"
                        style={{ marginBottom: "20px", minWidth: "250px" }}
                        key={menu.place_id}
                      >
                        <div
                          className="card card-fancy"
                          style={{ borderRadius: "15px" }}
                        >
                          <div className="card-body">
                            <div className="card-title">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                  {/* <div className="icon">{menu.types[0]}</div> */}
                                  <div
                                    className="ms-2 text-center"
                                    title={menu.name}
                                  >
                                    <h6
                                      className="mb-0"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {menu.name.length > 30
                                        ? menu.name.substr(0, 30) + "..."
                                        : menu.name}
                                    </h6>
                                  </div>
                                </div>
                                <div className="badge-custom">
                                  <span title="save">
                                    <button
                                      type="button"
                                      id="save"
                                      style={{ color: "red" }}
                                      className={
                                        menu.bookmark
                                          ? "fa fa-heart"
                                          : "fa fa-heart-o"
                                      }
                                      onClick={(e) =>
                                        handleBookmarkToggle(e, menu.place_id)
                                      }
                                    ></button>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row  mt-2 mb-2">
                              <div
                                className="col text-center"
                                style={{ borderRight: "1px solid grey" }}
                              >
                                <small className="span-content">
                                  {menu.km} KM
                                </small>
                              </div>
                              <div
                                className="col text-center"
                                style={{ borderRight: "1px solid grey" }}
                              >
                                <span
                                  className="badge badge-pill ms-2 badge-custom-2"
                                  style={{
                                    backgroundColor: menu.open_now
                                      ? "green"
                                      : "red",
                                  }}
                                >
                                  {menu.open_now ? "OPEN" : "CLOSED"}
                                </span>
                              </div>
                              <div className="col text-center">
                                <span title="copy">
                                  <button
                                    type="button"
                                    className="fa fa-copy"
                                    onClick={(e) =>
                                      handleShare(
                                        menu.geometry.lat +
                                          "," +
                                          menu.geometry.lng,
                                        menu.name
                                      )
                                    }
                                  ></button>
                                </span>
                              </div>
                            </div>
                            <p
                              className="card-text text-center mt-4"
                              style={{ fontSize: "12px" }}
                              title={menu.vicinity}
                            >
                              {menu.vicinity.length > 45
                                ? menu.vicinity.substr(0, 45) + "..."
                                : menu.vicinity}
                            </p>
                            <div className="call-directions">
                              <span
                                className="btn btn-outline-success pull-left"
                                style={{
                                  borderRadius: "50px",
                                  fontSize: "10px",
                                }}
                                onClick={(e) =>
                                  handlePlaceDetails(menu.place_id)
                                }
                              >
                                <i
                                  className="fa fa-phone"
                                  aria-hidden="true"
                                  style={{ marginRight: "10px" }}
                                ></i>
                                Call
                              </span>

                              <span
                                className="btn btn-outline-primary pull-right"
                                style={{
                                  borderRadius: "50px",
                                  fontSize: "10px",
                                }}
                                onClick={(e) =>
                                  handleDirections(
                                    menu.geometry.lat + "," + menu.geometry.lng
                                  )
                                }
                              >
                                <i
                                  className="fa fa-location-arrow"
                                  aria-hidden="true"
                                  style={{ marginRight: "10px" }}
                                ></i>
                                Directions
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* ? */}
                {nearBySearchData &&
                  nearBySearchData.data &&
                  nearBySearchData.data.next_page_token !== undefined && (
                    <div className="d-grid gap-2 col-sm-8 offset-sm-2 mb-4">
                      <button
                        className="btn btn-outline-dark"
                        type="button"
                        style={{ borderRadius: "50px", minWidth: "100px" }}
                        onClick={() =>
                          loadMoreGooglePlaces(
                            nearBySearchData.data.next_page_token
                          )
                        }
                        disabled={isMoreDataLoading}
                      >
                        {isMoreDataLoading ? (
                          <i
                            className="fa fa-spinner fa-pulse"
                            aria-hidden="true"
                            style={{ marginRight: "20px" }}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-chevron-down"
                            style={{ marginRight: "10px" }}
                          ></i>
                        )}
                        More places ?
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
