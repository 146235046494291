import React, { useState, useEffect } from "react";
import Tabs from "../components/tabs/tabs";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { useDocTitle } from "../utils/customHooks";

export const FunctionalAccount = (props) => {
  const [doctitle, setDocTitle] = useDocTitle("Account");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [stateArr, setStateArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [stateError, setStateError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");

  useEffect(() => {
    let token = getToken();
    try {
      token = JSON.parse(token);
      getUserDetails(token.access_token)
        .then((response) => {
          // console.log(response.data);
          setName(response.data.user.name);
          setEmail(response.data.user.email);
          setAddress(response.data.user.address);
          setPincode(response.data.user.pincode);
          setCountry(response.data.user.country);
          setState(response.data.user.state);
          setCity(response.data.user.city);
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (e) {
      setIsLoggedIn(false);
      // console.log(e);
    }
  }, []);

  useEffect(() => {
    if (country !== "" || country !== null || country !== undefined) {
      setStateArr(State.getStatesOfCountry(country));
    }
  }, [country]);

  useEffect(() => {
    if (state !== "" || state !== null || state !== undefined) {
      setCityArr(City.getCitiesOfState(country, state));
    }
  }, [state, country]);

  const handleInput = (typ, value) => {
    if (typ === "name") {
      setName(value);
    } else if (typ === "email") {
      setEmail(value);
    } else if (typ === "address") {
      setAddress(value);
    } else if (typ === "pincode") {
      setPincode(value);
    } else if (typ === "country") {
      setCountry(value);
    } else if (typ === "state") {
      setState(value);
    } else if (typ === "city") {
      setCity(value);
    }
  };

  const getUserDetails = (access_token) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    // console.log(process.env.REACT_APP_SERVER_BASE_URL + process.env.REACT_APP_USER_URL);
    // console.log(headers);
    return axios.get(
      process.env.REACT_APP_SERVER_BASE_URL + process.env.REACT_APP_USER_URL,
      { headers: headers }
    );
  };

  const logout = (access_token) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    };
    // console.log(headers);
    return axios.post(
      process.env.REACT_APP_SERVER_BASE_URL + process.env.REACT_APP_LOGOUT_URL,
      { data: {} },
      { headers: headers }
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setIsLoadingUpdate(true);

    const payload = { name, address, pincode, email, country, state, city };
    const token = JSON.parse(getToken());

    await updateUser(payload, token.access_token)
      .then((response) => {
        alert(response.data.msg);
      })
      .catch((err) => {
        alert(err.response.data.msg);
        if (err.response.data.errors.name) {
          setNameError(err.response.data.errors.name[0]);
        }
        if (err.response.data.errors.email) {
          setEmailError(err.response.data.errors.email);
        }
        if (err.response.data.errors.address) {
          setAddressError(err.response.data.errors.address);
        }
        if (err.response.data.errors.pincode) {
          setPincodeError(err.response.data.errors.pincode);
        }
        if (err.response.data.errors.country) {
          setCountryError(err.response.data.errors.country);
        }
        if (err.response.data.errors.state) {
          setStateError(err.response.data.errors.state);
        }
        if (err.response.data.errors.city) {
          setCityError(err.response.data.errors.city);
        }
      });
    setIsLoadingUpdate(false);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    setIsLoadingLogout(true);

    try {
      const token = JSON.parse(getToken());
      if (token) {
        await logout(token.access_token)
          .then((response) => {
            alert(response.data.msg);
            localStorage.removeItem("token");
            localStorage.removeItem("access_token");
            localStorage.removeItem("loggedIn");
          })
          .catch((err) => {
            alert(err.response.data.msg);
            localStorage.removeItem("token");
            localStorage.removeItem("access_token");
            localStorage.removeItem("loggedIn");
          });
      } else {
        alert("Error");
      }
    } catch (e) {
      // console.log(e);
    }
    setIsLoggedIn(false);
    setIsLoadingLogout(false);
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const updateUser = (data, access_token) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    return axios.put(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_UPDATE_USER_URL,
      data,
      { headers }
    );
  };

  return !isLoggedIn ? (
    <Redirect to="/login" />
  ) : (
    <>
      <Tabs />

      <div
        className="container"
        style={{
          position: "relative",
          top: "10%",
          alignItems: "center",
        }}
      >
        <div className="row">
          <div className="col-md-8 offset-md-2 col-sm-12" style={{marginBottom: '150px'}}>
            <div className="auth-inner-card">
              <form className="row g-3" onSubmit={handleFormSubmit}>
                <h3 className="text-center" style={{ marginBottom: "30px" }}>
                  Edit Profile
                </h3>

                <div className="col-md-6">
                  <label htmlFor="name" className="form-label">
                    Full name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    autoComplete="off"
                    placeholder="Mr. John Doe"
                    name="name"
                    value={name}
                    onChange={(e) => handleInput("name", e.target.value)}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {nameError !== "" ? nameError : ""}
                  </span>
                </div>

                <div className="col-md-6">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    autoComplete="off"
                    placeholder="john_doe@mail.com"
                    name="email"
                    value={email}
                    onChange={(e) => handleInput("email", e.target.value)}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {emailError !== "" ? emailError : ""}
                  </span>
                </div>

                <div className="col-8">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    autoComplete="off"
                    placeholder="1234 Main St"
                    name="address"
                    value={address}
                    onChange={(e) => handleInput("address", e.target.value)}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {addressError !== "" ? addressError : ""}
                  </span>
                </div>

                <div className="col-4">
                  <label htmlFor="address" className="form-label">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pincode"
                    autoComplete="off"
                    placeholder="100001"
                    name="pincode"
                    value={pincode}
                    onChange={(e) => handleInput("pincode", e.target.value)}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {pincodeError !== "" ? pincodeError : ""}
                  </span>
                </div>

                <div className="col-md-4">
                  <label>Country</label>
                  <select
                    id="country"
                    className="form-select"
                    onChange={(e) => handleInput("country", e.target.value)}
                    value={country}
                  >
                    {Country.getAllCountries().map((cntry, index) => (
                      <option value={cntry.isoCode} key={index}>
                        {cntry.name}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {countryError !== "" ? countryError : ""}
                  </span>
                </div>

                <div className="col-md-4">
                  <label>State</label>
                  <select
                    id="state"
                    className="form-select"
                    value={state}
                    onChange={(e) => handleInput("state", e.target.value)}
                  >
                    {stateArr.map(function (s, index) {
                      return (
                        <option value={s.isoCode} key={index}>
                          {s.name}
                        </option>
                      );
                    })}
                  </select>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {stateError !== "" ? stateError : ""}
                  </span>
                </div>

                <div className="col-md-4">
                  <label>City</label>
                  <select
                    id="city"
                    className="form-select"
                    aria-label="Default select example"
                    value={city}
                    onChange={(e) => handleInput("city", e.target.value)}
                  >
                    {cityArr.map(function (c, index) {
                      return (
                        <option value={c.name} key={index}>
                          {c.name}
                        </option>
                      );
                    })}
                  </select>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {cityError !== "" ? cityError : ""}
                  </span>
                </div>

                <div className="d-grid gap-2 margin-10">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoadingUpdate}
                  >
                    {isLoadingUpdate && (
                      <i
                        className="fa fa-spinner fa-pulse"
                        aria-hidden="true"
                        style={{ marginRight: "20px" }}
                      ></i>
                    )}
                    Submit
                  </button>
                </div>

                <p
                  className="small-text"
                  style={{ marginTop: "10px", display: "inline-block" }}
                >
                  Want to sign off ?
                  <button
                    type="submit"
                    className="btn btn-sm btn-outline-dark"
                    style={{ borderRadius: "50px", marginLeft: "10px" }}
                    onClick={handleLogout}
                  >
                    {isLoadingLogout ? (
                      <i
                        className="fa fa-spinner fa-pulse"
                        aria-hidden="true"
                        style={{ marginRight: "20px" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-sign-out"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                      ></i>
                    )}
                    Logout
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
