import React from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

const Tabs = () => {
  return (
    <div style={{ zIndex: 200 }}>
      <div className="py-3 navbar-light fixed-bottom">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col"
              style={{ borderRight: "1px solid grey", minWidth: "80px" }}
            >
              <div className="text-center">
                <Link className="nav-link nav-link-text" to={"/explore"}>
                  <div>
                    <i className="fa fa-search"></i>
                  </div>
                  <div className="bottom-tab">Explore</div>
                </Link>
              </div>
            </div>

            <div
              className="col"
              style={{ borderRight: "1px solid grey", minWidth: "80px" }}
            >
              <div className="text-center">
                <Link className="nav-link nav-link-text" to={"/bookmark"}>
                  <div>
                    <i className="fa fa-heart"></i>
                  </div>
                  <div  className="bottom-tab">Favourites</div>
                </Link>
              </div>
            </div>

            <div
              className="col"
              style={{ borderRight: "1px solid grey", minWidth: "80px" }}
            >
              <div className="text-center">
                <Link className="nav-link nav-link-text" to={"/map"}>
                  <div>
                    <i className="fa fa-map-signs"></i>
                  </div>
                  <div  className="bottom-tab">Map</div>
                </Link>
              </div>
            </div>

            <div className="col" style={{ minWidth: "80px" }}>
              <div className="text-center">
                <Link className="nav-link nav-link-text" to={"/account"}>
                  <div>
                    <i className="fa fa-user-circle"></i>
                  </div>
                  <div  className="bottom-tab">Account</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
