import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import app_logo from "../assets/images/app_logo.jpg";
import app_logo2 from "../assets/images/any.png";

const loginUser = (data) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  let dataToSend = data;

  // console.log(dataToSend);

  try {
    const res = axios
      .post(
        process.env.REACT_APP_SERVER_BASE_URL + process.env.REACT_APP_LOGIN_URL,
        dataToSend,
        { headers }
      )
      .then((response) => response.data)
      .catch((err) => err.response.data);

    return res;
  } catch (err) {
    // console.log(err);
  }
};

function setToken(userToken) {
  localStorage.setItem("token", JSON.stringify(userToken));
  localStorage.setItem("access_token", userToken.access_token);
  localStorage.setItem("loggedIn", true);
}

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginError: "",
      redirect: false,
      isLoading: false,
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillMount() {
    document.title = "Login";
  }

  handleTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handleFormSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const token = await loginUser({
      email: this.state.email,
      password: this.state.password,
    });
    // console.log(token);

    if (token.statusCode === 200) {
      alert("User logged in");
      setToken(token);
      this.setState({ redirect: true });
    } else {
      alert("Login failed");
      this.setState({ loginError: token.msg });
    }

    this.setState({ email: "", password: "" });
    this.setState({ isLoading: false });
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/explore" />;
    }

    return (
      <div
        className="container"
        style={{
          position: "relative",
          top: "10%",
          bottom: "10%",
          alignItems: "center",
        }}
      >
        <div className="row">
          <div className="col-md-6 offset-md-3 col-sm-12">
            <div className="auth-inner-card">
              <div className="text-center">
                <img
                  src={app_logo2}
                  alt="Any Information"
                  className="img-responsive mb-4"
                  style={{ height: "100px" }}
                />
              </div>
              <form onSubmit={this.handleFormSubmit}>
                <h3 className="text-center" style={{ marginBottom: "30px" }}>
                  Please Login to Continue !
                </h3>

                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleTextChange}
                  />
                </div>

                <div className="col-md-12">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Enter password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleTextChange}
                  />
                </div>

                <div className="form-group margin-10">
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.loginError !== "" ? this.state.loginError : ""}
                  </span>
                </div>

                {/* <div className="form-group margin-10">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                      style={{ marginRight: "10px" }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      Remember me
                    </label>
                  </div>
                </div> */}

                <div className="d-grid gap-2 margin-10">
                  <button
                    type="submit"
                    className="btn btn-primary btn-md btn-block"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading && (
                      <i
                        className="fa fa-spinner fa-pulse"
                        aria-hidden="true"
                        style={{ marginRight: "20px" }}
                      ></i>
                    )}
                    Submit
                  </button>
                </div>

                <div className="d-grid gap-2 margin-10">
                  <Link
                    to={"/explore"}
                    className="btn btn-sm btn-dark pull-right"
                  >
                    Skip
                  </Link>
                </div>

                <p
                  className="small-text pull-left"
                  style={{ marginTop: "10px" }}
                >
                  <Link to={"/forgot"}>Forgot password ?</Link>
                </p>

                <p
                  className="small-text text-right"
                  style={{ marginTop: "10px" }}
                >
                  Dont have account ? <Link to={"/register"}>Register</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
