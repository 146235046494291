import React, { Component } from "react";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { Redirect } from "react-router";
import dotenv from "dotenv";
import { Link } from "react-router-dom";
dotenv.config();

async function registerUser(data) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return axios
    .post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_REGISTER_URL,
      data,
      { headers }
    )
    .then((response) => response.data)
    .catch((err) => err.response.data);
}


export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      address: "",
      pincode: "",
      password: "",
      countryCode: "",
      countryName: "",
      stateCode: "",
      stateName: "",
      cityName: "",
      stateArray: [],
      cityArray: [],
      savedUser: "",
      nameError: "",
      emailError: "",
      addressError: "",
      pincodeError: "",
      passwordError: "",
      countryError: "",
      stateError: "",
      cityError: "",
      redirect: false,
      isLoading: false,
    };

    this.getCountry = this.getCountry.bind(this);
    this.getState = this.getState.bind(this);
    this.getCity = this.getCity.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  getCountry(e) {
    this.setState({
      countryCode: e.target.value,
      countryName: Country.getCountryByCode(e.target.value).name,
    });
    this.setState({ stateArray: State.getStatesOfCountry(e.target.value) });
    // setTimeout(() => console.log(this.state), 300);
  }

  getState(e) {
    this.setState({
      stateCode: e.target.value,
      stateName: State.getStateByCodeAndCountry(
        e.target.value,
        this.state.countryCode
      ).name,
    });
    this.setState({
      cityArray: City.getCitiesOfState(this.state.countryCode, e.target.value),
    });
  }

  getCity(e) {
    this.setState({ cityName: e.target.value });
  }

  handleTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    // setTimeout(() => console.log(this.state), 300);
  }

 

  async handleFormSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const response = await registerUser({
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      address: this.state.address,
      pincode: this.state.pincode,
      country: this.state.countryName,
      state: this.state.stateName,
      city: this.state.cityName,
    });
    // console.log(response);

    if (response.errors) {
      if (response.errors.name) {
        this.setState({ nameError: response.errors.name[0] });
      }
      if (response.errors.email) {
        this.setState({ emailError: response.errors.email[0] });
      }
      if (response.errors.password) {
        this.setState({
          passwordError: response.errors.password[0],
        });
      }
      if (response.errors.address) {
        this.setState({
          addressError: response.errors.address[0],
        });
      }
      if (response.errors.pincode) {
        this.setState({
          pincodeError: response.errors.pincode[0],
        });
      }
      if (response.errors.city) {
        this.setState({ cityError: response.errors.city[0] });
      }
      if (response.errors.state) {
        this.setState({ stateError: response.errors.state[0] });
      }
      if (response.errors.country) {
        this.setState({
          countryError: response.errors.country[0],
        });
      }
    } else {
      alert(response.msg);
      if(response.statusCode === 200) {
        this.setState({redirect: true});
      } else {
        this.setState({ defaultError: response.msg });
      }
    }

    
    this.setState({ isLoading: false });
  }

  componentWillMount() {
    document.title = "Register";
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/login" />;
    }

    return (
      <div
        className="container"
        style={{
          position: "relative",
          top: "10%",
          bottom: "10%",
          alignItems: "center",
        }}
      >
        <div className="row">
          <div className="col-md-8 offset-md-2 col-sm-12">
            <div className="auth-inner-card">
              <form className="row g-3" onSubmit={this.handleFormSubmit}>
                <h3 className="text-center" style={{ marginBottom: "30px" }}>
                  Register
                </h3>

                <div className="col-md-6">
                  <label htmlFor="name" className="form-label">
                    Full name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    autoComplete="off"
                    placeholder="Mr. John Doe"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.nameError !== "" ? this.state.nameError : ""}
                  </span>
                </div>

                <div className="col-md-6">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    autoComplete="off"
                    placeholder="john_doe@mail.com"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.emailError !== "" ? this.state.emailError : ""}
                  </span>
                </div>

                <div className="col-8">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    autoComplete="off"
                    placeholder="1234 Main St"
                    name="address"
                    value={this.state.address}
                    onChange={this.handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.addressError !== ""
                      ? this.state.addressError
                      : ""}
                  </span>
                </div>

                <div className="col-4">
                  <label htmlFor="address" className="form-label">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pincode"
                    autoComplete="off"
                    placeholder="100001"
                    name="pincode"
                    value={this.state.pincode}
                    onChange={this.handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.pincodeError !== ""
                      ? this.state.pincodeError
                      : ""}
                  </span>
                </div>

                <div className="col-md-4">
                  <label>Country</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={this.getCountry}
                  >
                    {Country.getAllCountries().map((country, index) => (
                      <option value={country.isoCode} key={index}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.countryError !== ""
                      ? this.state.countryError
                      : ""}
                  </span>
                </div>

                <div className="col-md-4">
                  <label>State</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={this.getState}
                  >
                    {this.state.stateArray.map(function (state, index) {
                      return (
                        <option value={state.isoCode} key={index}>
                          {state.name}
                        </option>
                      );
                    })}
                  </select>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.stateError !== "" ? this.state.stateError : ""}
                  </span>
                </div>

                <div className="col-md-4">
                  <label>City</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={this.getCity}
                  >
                    {this.state.cityArray.map(function (city, index) {
                      return (
                        <option value={city.isoCode} key={index}>
                          {city.name}
                        </option>
                      );
                    })}
                  </select>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.cityError !== "" ? this.state.cityError : ""}
                  </span>
                </div>

                <div className="col-md-4">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    name="password"
                    autoComplete="off"
                    value={this.state.password}
                    onChange={this.handleTextChange}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.passwordError !== ""
                      ? this.state.passwordError
                      : ""}
                  </span>
                </div>

                <div className="d-grid gap-2 margin-10">
                  <button
                    type="submit"
                    className="btn btn-primary btn-md btn-block"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading && (
                      <i
                        className="fa fa-spinner fa-pulse"
                        aria-hidden="true"
                        style={{ marginRight: "20px" }}
                      ></i>
                    )}
                    Submit
                  </button>
                </div>

                <p
                  className="small-text text-right"
                  style={{ marginTop: "10px" }}
                >
                  Already registered ? <Link to={"/login"}>Login</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
