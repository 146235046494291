import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router";

async function forgotPassword(data) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return axios
    .post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_FORGOT_PASSWORD_URL,
      data,
      { headers }
    )
    .then((response) => response.data)
    .catch((err) => err.response.data);
}

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      forgotPasswordError: "",
      isLoading: false,
      redirect: false,
      redirectTo: "",
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentWillMount() {
    document.title = "Forgot Password";
  }

  handleTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handleFormSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    const token = await forgotPassword({
      email: this.state.email,
    });

    this.setState({ email: "" });
    this.setState({ isLoading: false });

    if (token.statusCode === 200) {
      alert(token.msg);
      this.setState({ redirect: true, redirectTo: "/reset" });
    } else {
      alert(token.msg);
      this.setState({ forgotPasswordError: token.msg });
      this.setState({ redirect: true, redirectTo: "/login" });
    }

   
  }

  render() {
    const { redirect, redirectTo } = this.state;

    if (redirect) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <div
        className="container"
        style={{
          position: "relative",
          top: "10%",
          bottom: "10%",
          alignItems: "center",
        }}
      >
        <div className="row">
          <div className="col-md-4 offset-md-4 col-sm-12">
            <div className="auth-inner-card">
              <form onSubmit={this.handleFormSubmit}>
                <h3 className="text-center" style={{ marginBottom: "30px" }}>
                  Forgot Password
                </h3>

                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleTextChange}
                  />
                </div>

                <div className="form-group margin-10">
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {this.state.forgotPasswordError !== ""
                      ? this.state.forgotPasswordError
                      : ""}
                  </span>
                </div>

                <div className="d-grid gap-2 margin-10">
                  <button
                    type="submit"
                    className="btn btn-primary btn-md btn-block"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading && (
                      <i
                        className="fa fa-spinner fa-pulse"
                        aria-hidden="true"
                        style={{ marginRight: "20px" }}
                      ></i>
                    )}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
