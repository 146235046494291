import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
  position: "absolute",
  width: "100%",
  height: "100%",
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: null,
      lng: null,
    };
  }

  currentPos = (position) => {
    // console.log("Latitude is :", position.coords.latitude);
    // console.log("Longitude is :", position.coords.longitude);
    this.setState({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
    // setTimeout(() => console.log(this.state), 300);
  };

  componentWillMount() {
    navigator.geolocation.getCurrentPosition(this.currentPos);
  }

  moveMarker = (t,map,position) => {
    const {latLng} = position;
    console.log(position);
    this.setState({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
    this.props.handleLatLong(latLng.lat(),latLng.lng());

    setTimeout(() => {
      console.log(this.state);
    }, 300)
  };

  render() {
    return (
      <Map
        google={this.props.google}
        style={mapStyles}
        initialCenter={{ lat: 19.076, lng: 72.8777 }}
        fullscreenControl={false}
        zoomControl={false}
        zoom={12}
        mapTypeControl={false}
      >
        {/* <Marker position={{ lat: this.state.lat, lng: this.state.lng }} /> */}

        <Marker
          onDoubleClick={this.onMarkerClick}
          name={"Current location"}
          position={{ lat: this.state.lat, lng: this.state.lng }}
          draggable={true}
          onDragend={this.moveMarker.bind(this)}
          changeLat
          changeLng
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_LIVE_KEY,
})(MapContainer);

//
