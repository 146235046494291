import React, { useState, useEffect } from "react";
import Tabs from "../components/tabs/tabs";
import { FaMapMarkedAlt } from "react-icons/fa";
import { exploreArray } from "../dummyData/explore";
import MapContainer from "../components/MapContainer";
import { searchArray } from "../dummyData/searchData";
import { useDocTitle } from "../utils/customHooks";
import { exploreList } from "../assets/json/exploreList";
import axios from "axios";
import spinnerLogo2 from "../assets/gif/location-4.gif";

const onWheelType = (e) => {
  // e.preventDefault();
  const container = document.getElementById("type-content");
  const containerScrollPosition =
    document.getElementById("type-content").scrollLeft;
  container.scrollTo({
    top: 0,
    left: containerScrollPosition + e.deltaY,
    behaviour: "smooth",
  });
};

const onWheelDetail = (e) => {
  // e.preventDefault();
  const container = document.getElementById("detail-content");
  const containerScrollPosition =
    document.getElementById("detail-content").scrollLeft;
  container.scrollTo({
    top: 0,
    left: containerScrollPosition + e.deltaY,
    behaviour: "smooth",
  });
};

export const FunctionalMap = () => {
  const [doctitle, setDocTitle] = useDocTitle("Map");

  const [searchText, setSearchText] = useState("");
  const [exploreListArray, setExploreListArray] = useState(exploreList);
  const [nearBySearchData, setNearBySearchData] = useState([]);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [nearBySearchDataArray, setNearBySearchDataArray] = useState([]);
  const [responseSearch, setResponseSearch] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isMoreDataLoading, setIsMoreDataLoading] = useState(false);

  useEffect(() => {
    const pos = navigator.geolocation.getCurrentPosition(function (position) {
      // console.log(position);
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  }, []);

  useEffect(() => {
    if (searchText) {
      // console.log( exploreList.filter((list) => list.title.toLowerCase().includes(searchText.toLowerCase())) );
      setNearBySearchData([]);
      setNearBySearchDataArray([]);
      let filterExploreData = exploreList.filter((list) =>
        list.title.toLowerCase().includes(searchText.toLowerCase())
      );
      const defaultExploreData = exploreList.find(
        (list) => list.default === true
      );
      defaultExploreData.title = searchText;
      if (filterExploreData.length) {
        setExploreListArray(filterExploreData);
      } else {
        setExploreListArray([defaultExploreData]);
      }
    } else {
      setExploreListArray(exploreList);
    }
  }, [searchText, `${exploreList}`]);

  const handlePillClick = async (value) => {
    setKeyword(value);
    setIsDataLoading(true);

    let token = getToken();
    let data = {
      location: lat + "," + lng,
      keyword: value,
    };

    setNearBySearchDataArray([]);
    setNearBySearchData([]);

    try {
      // token = JSON.parse(token);
      await getByKeyword(data)
        .then((response) => {
          // console.log(response.data);
          setNearBySearchDataArray(response.data);
          setNearBySearchData(response.data);
        })
        .catch((err) => {
          // console.log(err)
          if (err.response && err.response.data && err.response.data.msg) {
            alert(err.response.data.msg);
          }
        });
    } catch (e) {
      // console.log(e);
    }

    setIsDataLoading(false);
  };

  const getByKeyword = async (data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // console.log(process.env.REACT_APP_SERVER_BASE_URL + process.env.REACT_APP_NEARBYSEARCH_URL);
    // console.log(headers);
    return await axios.post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_NEARBYSEARCH_URL_WITHOUT_AUTH,
      data,
      { headers: headers }
    );
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const handleResponseSearch = (responseSearch) => {
    if (responseSearch) {
    }
  };

  const toggleBookmark = async (access_token, data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    return await axios.post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_BOOKMARK_TOGGLE_URL,
      data,
      { headers: headers }
    );
  };

  const handleBookmarkToggle = (e, value) => {
    if (e.target.classList.contains("fa-heart")) {
      e.target.classList.remove("fa-heart");
      e.target.classList.add("fa-heart-o");
    } else {
      e.target.classList.remove("fa-heart-o");
      e.target.classList.add("fa-heart");
    }

    const bookmarkData = nearBySearchDataArray.data.results.filter(
      (menu) => menu.place_id === value
    );

    let token = getToken();
    let data = bookmarkData[0];

    if (value && bookmarkData[0].place_id) {
      if (token) {
        try {
          token = JSON.parse(token);
          toggleBookmark(token.access_token, data)
            .then((response) => {
              console.log(response.data.msg);
              // alert(response.data.msg);
            })
            .catch((err) => {
              console.log(err.response.data.msg);
              // alert(err.response.data.msg);
            });
        } catch (e) {
          // console.log(e);
        }
      } else {
        let favourites = localStorage.getItem("favourites");
        if (favourites) {
          try {
            favourites = JSON.parse(favourites);

            let fav1 = favourites.find(
              (o) => o.place_id === bookmarkData[0].place_id
            );

            if (!fav1) {
              bookmarkData[0].bookmark = true;
              favourites.push(bookmarkData[0]);
              const serializedState = JSON.stringify(favourites);
              localStorage.setItem("favourites", serializedState);
            }
          } catch (err) {
            return undefined;
          }
        } else {
          try {
            const serializedState = JSON.stringify(bookmarkData);
            localStorage.setItem("favourites", serializedState);
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  };

  const getPlaceDetails = async (data) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    return await axios.post(
      process.env.REACT_APP_SERVER_BASE_URL +
        process.env.REACT_APP_PLACE_DETAILS_URL_WITHOUT_AUTH,
      data,
      { headers: headers }
    );
  };

  const handlePlaceDetails = (value) => {
    let token = getToken();
    let data = {
      place_id: value,
    };

    if (value) {
      try {
        // token = JSON.parse(token);
        getPlaceDetails(data)
          .then((response) => {
            // console.log(response.data);
            if (
              response.data.data &&
              response.data.data.result &&
              response.data.data.result.international_phone_number
            ) {
              var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
              // alert(response.data.data.result.international_phone_number);
              if (isMobile) {
                window.open('tel:'+response.data.data.result.international_phone_number);
              } else {
                navigator.clipboard.writeText(response.data.data.result.international_phone_number);
                alert(response.data.data.result.international_phone_number + " : Copied");
              }
            } else {
              alert("Phone not available");
            }
          })
          .catch((err) => {
            // console.log(err)
            alert(err.response);
          });
      } catch (e) {
        // console.log(e);
      }
    }
  };

  const handleShare = (geometry, name) => {
    const url = process.env.REACT_APP_SHARE_LINK + geometry;
    navigator.clipboard.writeText(url);
    alert(name + "\n" + "location copied successfully");
  };

  const handleDirections = (geometry) => {
    const url = process.env.REACT_APP_SHARE_LINK + geometry;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const loadMoreGooglePlaces = async (next_page_token) => {
    // alert(next_page_token)
    if (next_page_token) {
      // let token = getToken();
      // token = JSON.parse(token);
      let data = {
        next_page_token: next_page_token,
        location: lat + "," + lng,
        keyword: keyword,
      };

      setIsMoreDataLoading(true);

      await getByKeyword(data)
        .then((response) => {
          let newResult = { ...nearBySearchData };
          newResult.data.next_page_token = response.data.data.next_page_token;
          newResult.data.results.push(...response.data.data.results);

          // console.log('%O',newResult.data.next_page_token);
          // console.log('%N',response.data.data.next_page_token);
          // console.log(newResult);
          setNearBySearchData(newResult);
        })
        .catch((err) => {
          // console.log(err);
        });

      setIsMoreDataLoading(false);
    } else {
      // console.log("%NPT", next_page_token);
    }
  };
  const handleLatLong = (lat, long) => {
    console.log("marker moved new location found!!");
    setLat(lat);
    setLng(long);
    setNearBySearchData([]);
    setNearBySearchDataArray([]);
  };

  return (
    <>
      <Tabs />

      <div>
        <MapContainer handleLatLong={handleLatLong} />
      </div>

      {isDataLoading && (
        <div className="centered">
          <div className="img img-responsive" role="status">
            {/* <span className="visually-hidden">Loading...</span> */}
            <img src={spinnerLogo2} alt="loader" style={{ height: "100px" }} />
            Loading
          </div>
        </div>
      )}

      <div
        className="container-fluid"
        style={{ zIndex: 10, position: "relative" }}
      >
        <div className="row">
          <div className="col-md-6 offset-md-3 col-sm-12">
            <div style={{ borderRadius: "50px", marginTop: "20px" }}>
              <div className="SearchBox">
                <span
                  className="SearchBox-pin"
                  style={{ backgroundColor: "white" }}
                >
                  <FaMapMarkedAlt style={{ fontSize: "30px" }} />
                </span>

                <input
                  type="text"
                  placeholder="Search here"
                  style={{ width: "100%", padding: "20px 0px 20px 10px" }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />

                <button className="SearchBox-button pull-right">
                  <i className="fa fa-search" style={{ fontSize: "25px" }}></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{ zIndex: 10, position: "relative" }}
      >
        <div className="row">
          <div
            className="col-sm-6 offset-sm-3"
            id="type-content"
            style={{ whiteSpace: "nowrap", overflowX: "scroll" }}
            onWheel={onWheelType}
          >
            {exploreListArray.map(function (list, index) {
              return (
                <span
                  type="button"
                  className="btn light-content"
                  key={index}
                  style={{
                    border: "1px solid grey",
                    margin: "10px",
                    padding: "7px",
                    borderRadius: "50px",
                    display: "inline-block",
                  }}
                  onClick={(e) => handlePillClick(list.title)}
                >
                  <img
                    src={list.img}
                    alt=""
                    style={{
                      height: "15px",
                      width: "15px",
                      marginRight: "10px",
                    }}
                  />
                  {list.title.replace(/(^|\s)\S/g, (l) => l.toUpperCase())}
                </span>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className="container-fluid fixed-bottom"
        style={{ marginBottom: "100px" }}
      >
        <div className="row">
          <div className="col-sm-12">
            <div
              className="d-flex flex-row"
              style={{ overflow: "auto" }}
              id="detail-content"
              onWheel={onWheelDetail}
            >
              {nearBySearchDataArray.data &&
                nearBySearchDataArray.data.results &&
                nearBySearchDataArray.data.results.map(function (menu, index) {
                  return (
                    <div
                      className="col-sm-3 card-block"
                      style={{ marginRight: "20px", minWidth: "250px" }}
                    >
                      <div className="card" style={{ borderRadius: "15px" }}>
                        <div className="card-body">
                          <div className="card-title">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex flex-row align-items-center">
                                <div className="ms-2" title={menu.name}>
                                  <h6
                                    className="mb-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {menu.name.length > 30
                                      ? menu.name.substr(0, 30) + "..."
                                      : menu.name}
                                  </h6>
                                </div>
                              </div>
                              <div className="badge-custom">
                                <span title="save">
                                  <button
                                    type="button"
                                    id="save"
                                    style={{ color: "red" }}
                                    className={
                                      menu.bookmark
                                        ? "fa fa-heart"
                                        : "fa fa-heart-o"
                                    }
                                    onClick={(e) =>
                                      handleBookmarkToggle(e, menu.place_id)
                                    }
                                  ></button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row  mt-2 mb-2">
                            <div
                              className="col text-center"
                              style={{ borderRight: "1px solid grey" }}
                            >
                              <small className="span-content">
                                {menu.km} KM
                              </small>
                            </div>
                            <div
                              className="col text-center"
                              style={{ borderRight: "1px solid grey" }}
                            >
                              <span
                                className="badge badge-pill ms-2 badge-custom-2"
                                style={{
                                  backgroundColor: menu.open_now
                                    ? "green"
                                    : "red",
                                }}
                              >
                                {menu.open_now ? "OPEN" : "CLOSED"}
                              </span>
                            </div>
                            <div className="col text-center">
                              <span title="copy">
                                <button
                                  type="button"
                                  id="save"
                                  className="fa fa-copy"
                                  onClick={(e) =>
                                    handleShare(
                                      menu.geometry.lat +
                                        "," +
                                        menu.geometry.lng,
                                      menu.name
                                    )
                                  }
                                ></button>
                              </span>
                            </div>
                          </div>
                          <p
                            className="card-text"
                            style={{ fontSize: "12px" }}
                            title={menu.vicinity}
                          >
                            {menu.vicinity.length > 50
                              ? menu.vicinity.substr(0, 50) + "..."
                              : menu.vicinity}
                          </p>
                          <div className="call-directions">
                            <span
                              className="btn btn-outline-success pull-left"
                              style={{ borderRadius: "50px", fontSize: "10px" }}
                              onClick={(e) => handlePlaceDetails(menu.place_id)}
                            >
                              <i
                                className="fa fa-phone"
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Call
                            </span>

                            <span
                              className="btn btn-outline-primary pull-right"
                              style={{ borderRadius: "50px", fontSize: "10px" }}
                              onClick={(e) =>
                                handleDirections(
                                  menu.geometry.lat + "," + menu.geometry.lng
                                )
                              }
                            >
                              <i
                                className="fa fa-location-arrow"
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Directions
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {nearBySearchData &&
                nearBySearchData.data &&
                nearBySearchData.data.next_page_token !== undefined && (
                  <div className="d-grid gap-2 col-sm-2">
                    <button
                      className="btn btn-dark"
                      type="button"
                      style={{ borderRadius: "50px" }}
                      onClick={() =>
                        loadMoreGooglePlaces(
                          nearBySearchData.data.next_page_token
                        )
                      }
                      disabled={isMoreDataLoading}
                    >
                      {isMoreDataLoading ? (
                        <i
                          className="fa fa-spinner fa-pulse"
                          aria-hidden="true"
                          style={{ marginRight: "20px" }}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-chevron-right"
                          style={{ marginRight: "10px" }}
                        ></i>
                      )}
                      More places ?
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
