


import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { HashRouter as Router, Switch, Route } from "react-router-dom";


import Login from "./pages/login";
import Register from "./pages/register";
// import Explore from "./pages/explore";
// import Bookmark from "./pages/bookmark";
// import Map from "./pages/map";
// import Account from "./pages/account";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import { PrivateRoute } from "./middleware/privateRoute";
import { FunctionalAccount } from "./pages/functionalAccount";
import { FunctionalExplore } from "./pages/functionalExplore";
import { FunctionalBookmark } from "./pages/functionalBookmark";
import { FunctionalMap } from "./pages/functionalMap";
import { FunctionalNotFound } from "./pages/functionalNotFound";
// import { FunctionalSidebar } from "./pages/functionalSidebar";
import {createBrowserHistory} from 'history';
const browserHistory = createBrowserHistory();


function App() {
  return (
    <Router  history={browserHistory}>
      <Switch>
        <Route exact path="/" component={FunctionalExplore} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />        
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/account" component={FunctionalAccount} />
        <Route exact path="/explore" component={FunctionalExplore} />
        <Route exact path="/bookmark" component={FunctionalBookmark} />
        <Route exact path="/map" component={FunctionalMap} />      
        <Route path="*" component={FunctionalNotFound} />
      </Switch>
    </Router>
  );
}

export default App;
