export const exploreList = [
  {
    title: "Emergency",
    checked: "false",
    img: require("../images/emergency.png").default,
  },
  {
    title: "Chemist",
    checked: "false",
    img: require("../images/chemist.png").default,
  },
  {
    title: "ATM",
    checked: "false",
    img: require("../images/atm.png").default,
  },
  {
    title: "Taxi",
    checked: "false",
    img: require("../images/taxi_stand.png").default,
  },
  {
    title: "Restaurant",
    checked: "false",
    img: require("../images/restaurant.png").default,
  },
  {
    title: "Fast Food",
    checked: "false",
    img: require("../images/fast_food.png").default,
  },
  {
    title: "Coffee Shop",
    checked: "false",
    img: require("../images/coffee_shop.png").default,
  },
  {
    title: "Super Market",
    checked: "false",
    img: require("../images/supermarket.png").default,
  },
  {
    title: "Shopping Mall",
    checked: "false",
    img: require("../images/shopping_mall.png").default,
  },
  {
    title: "Movie Theater",
    checked: "false",
    img: require("../images/movie_theater.png").default,
  },
  {
    title: "McDonald's",
    checked: "false",
    img: require("../images/fast_food.png").default,
  },
  {
    title: "Pizza",
    checked: "false",
    img: require("../images/pizza.png").default,
  },
  {
    title: "KFC",
    checked: "false",
    img: require("../images/kfc.png").default,
  },
  {
    title: "Mobile Repair",
    checked: "false",
    img: require("../images/mobile_repair.png").default,
  },
  {
    title: "Provision Store",
    checked: "false",
    img: require("../images/home_goods_store.png").default,
  },
  {
    title: "Electrician",
    checked: "false",
    img: require("../images/electrician.png").default,
  },
  {
    title: "Plumber",
    checked: "false",
    img: require("../images/plumber.png").default,
  },
  {
    title: "Hospital",
    checked: "false",
    img: require("../images/hospital.png").default,
  },
  {
    title: "Nursing Home",
    checked: "false",
    img: require("../images/nursing_care.png").default,
  },
  {
    title: "Doctor",
    checked: "false",
    img: require("../images/doctor.png").default,
  },
  {
    title: "Dentist",
    checked: "false",
    img: require("../images/dentist.png").default,
  },
  {
    title: "Bank",
    checked: "false",
    img: require("../images/bank.png").default,
  },
  {
    title: "Beauty Salon",
    checked: "false",
    img: require("../images/beauty_salon.png").default,
  },
  {
    title: "Bar",
    checked: "false",
    img: require("../images/bar.png").default,
  },
  {
    title: "Car Repair",
    checked: "false",
    img: require("../images/car_repair.png").default,
  },
  {
    title: "Florist",
    checked: "false",
    img: require("../images/florist.png").default,
  },
  {
    title: "Gym",
    checked: "false",
    img: require("../images/gym.png").default,
  },
  {
    title: "Spa",
    checked: "false",
    img: require("../images/spa.png").default,
  },
  {
    title: "Hardware Store",
    checked: "false",
    img: require("../images/hardware_store.png").default,
  },
  {
    title: "Laundry",
    checked: "false",
    img: require("../images/laundry.png").default,
  },
  {
    title: "Real Estate Agency",
    checked: "false",
    img: require("../images/real_estate_agency.png").default,
  },
  {
    title: "School",
    checked: "false",
    img: require("../images/school.png").default,
  },
  {
    title: "College",
    checked: "false",
    img: require("../images/collage.png").default,
  },
  {
    title: "Book Stall",
    checked: "false",
    img: require("../images/book_store.png").default,
  },
  {
    title: "Accounting",
    checked: "false",
    img: require("../images/accounting.png").default,
  },
  {
    title: "Airport",
    checked: "false",
    img: require("../images/airport.png").default,
  },
  {
    title: "Amusement Park",
    checked: "false",
    img: require("../images/amusement_park.png").default,
  },
  {
    title: "Aquarium",
    checked: "false",
    img: require("../images/aquarium.png").default,
  },
  {
    title: "Art Gallery",
    checked: "false",
    img: require("../images/art_gallery.png").default,
  },
  {
    title: "Bakery",
    checked: "false",
    img: require("../images/bakery.png").default,
  },
  {
    title: "Bicycle Store",
    checked: "false",
    img: require("../images/bicycle_store.png").default,
  },
  {
    title: "Bowling Alley",
    checked: "false",
    img: require("../images/bowling_alley.png").default,
  },
  {
    title: "Bus Station",
    checked: "false",
    img: require("../images/bus_station.png").default,
  },
  {
    title: "Cafe",
    checked: "false",
    img: require("../images/cafe.png").default,
  },
  {
    title: "Camp Ground",
    checked: "false",
    img: require("../images/campground.png").default,
  },
  {
    title: "Car Dealer",
    checked: "false",
    img: require("../images/car_dealer.png").default,
  },
  {
    title: "Car Rental",
    checked: "false",
    img: require("../images/car_rental.png").default,
  },
  {
    title: "Car Wash",
    checked: "false",
    img: require("../images/car_wash.png").default,
  },
  {
    title: "Casino",
    checked: "false",
    img: require("../images/casino.png").default,
  },
  {
    title: "Cemetery",
    checked: "false",
    img: require("../images/cemetery.png").default,
  },
  {
    title: "Church",
    checked: "false",
    img: require("../images/church.png").default,
  },
  {
    title: "City Hall",
    checked: "false",
    img: require("../images/city_hall.png").default,
  },
  {
    title: "Clothing Store",
    checked: "false",
    img: require("../images/clothing_store.png").default,
  },
  {
    title: "Convenience Store",
    checked: "false",
    img: require("../images/convenience_store.png").default,
  },
  {
    title: "Court House",
    checked: "false",
    img: require("../images/courthouse.png").default,
  },
  {
    title: "Department Store",
    checked: "false",
    img: require("../images/department_store.png").default,
  },
  {
    title: "Electronics Store",
    checked: "false",
    img: require("../images/electronics_store.png").default,
  },
  {
    title: "Embassy",
    checked: "false",
    img: require("../images/embassy.png").default,
  },
  {
    title: "Fire Station",
    checked: "false",
    img: require("../images/fire_station.png").default,
  },
  {
    title: "Funeral Home",
    checked: "false",
    img: require("../images/funeral_home.png").default,
  },
  {
    title: "Furniture Store",
    checked: "false",
    img: require("../images/furniture_store.png").default,
  },
  {
    title: "Gas Station",
    checked: "false",
    img: require("../images/gas_station.png").default,
  },
  {
    title: "Hair Care",
    checked: "false",
    img: require("../images/hair_care.png").default,
  },
  {
    title: "Home Goods Store",
    checked: "false",
    img: require("../images/home_goods_store.png").default,
  },
  {
    title: "Insurance Agency",
    checked: "false",
    img: require("../images/insurance_agency.png").default,
  },
  {
    title: "Jewellery Store",
    checked: "false",
    img: require("../images/jewelry_store.png").default,
  },
  {
    title: "Lawyer",
    checked: "false",
    img: require("../images/lawyer.png").default,
  },
  {
    title: "Library",
    checked: "false",
    img: require("../images/library.png").default,
  },
  {
    title: "Liquor Store",
    checked: "false",
    img: require("../images/liquor_store.png").default,
  },
  {
    title: "Local Government Office",
    checked: "false",
    img: require("../images/local_government_office.png").default,
  },
  {
    title: "Locksmith",
    checked: "false",
    img: require("../images/locksmith.png").default,
  },
  {
    title: "Lodging",
    checked: "false",
    img: require("../images/lodging.png").default,
  },
  {
    title: "Meal Delivery",
    checked: "false",
    img: require("../images/meal_delivery.png").default,
  },
  {
    title: "Meal Takeaway",
    checked: "false",
    img: require("../images/meal_takeaway.png").default,
  },
  {
    title: "Mosque",
    checked: "false",
    img: require("../images/mosque.png").default,
  },
  {
    title: "Movie Rental",
    checked: "false",
    img: require("../images/movie_rental.png").default,
  },
  {
    title: "Moving Company",
    checked: "false",
    img: require("../images/moving_company.png").default,
  },
  {
    title: "Museum",
    checked: "false",
    img: require("../images/museum.png").default,
  },
  {
    title: "Night Club",
    checked: "false",
    img: require("../images/night_club.png").default,
  },
  {
    title: "Painter",
    checked: "false",
    img: require("../images/painter.png").default,
  },
  {
    title: "Park",
    checked: "false",
    img: require("../images/park.png").default,
  },
  {
    title: "Parking",
    checked: "false",
    img: require("../images/parking.png").default,
  },
  {
    title: "Pet Store",
    checked: "false",
    img: require("../images/pet_store.png").default,
  },
  {
    title: "Pharmacy",
    checked: "false",
    img: require("../images/pharmacy.png").default,
  },
  {
    title: "Physiotherapist",
    checked: "false",
    img: require("../images/physiotherapist.png").default,
  },
  {
    title: "Police",
    checked: "false",
    img: require("../images/police.png").default,
  },
  {
    title: "Post Office",
    checked: "false",
    img: require("../images/post_office.png").default,
  },
  {
    title: "Roofing Contractor",
    checked: "false",
    img: require("../images/roofing_contractor.png").default,
  },
  {
    title: "RV Park",
    checked: "false",
    img: require("../images/rv_park.png").default,
  },
  {
    title: "Shoe Store",
    checked: "false",
    img: require("../images/shoe_store.png").default,
  },
  {
    title: "Stadium",
    checked: "false",
    img: require("../images/stadium.png").default,
  },
  {
    title: "Storage",
    checked: "false",
    img: require("../images/storage.png").default,
  },
  {
    title: "Store",
    checked: "false",
    img: require("../images/store.png").default,
  },
  {
    title: "Subway Station",
    checked: "false",
    img: require("../images/subway_station.png").default,
  },
  {
    title: "Synagogue",
    checked: "false",
    img: require("../images/synagogue.png").default,
  },
  {
    title: "Train Station",
    checked: "false",
    img: require("../images/train_station.png").default,
  },
  {
    title: "Transit Station",
    checked: "false",
    img: require("../images/transit_station.png").default,
  },
  {
    title: "Travel Agency",
    checked: "false",
    img: require("../images/travel_agency.png").default,
  },
  {
    title: "Veterinary Care",
    checked: "false",
    img: require("../images/veterinary_care.png").default,
  },
  {
    title: "Zoo",
    checked: "false",
    img: require("../images/zoo.png").default,
  },
  {
    title: "A to Z",
    checked: "false",
    img: require("../images/a_to_z.png").default,
    default: true
  },
];
